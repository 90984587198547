import React, { useEffect } from "react"
import "./ContainerMain.scss"
import checkScrollTop from "helpers/checkScrollTop"

export default function ContainerMain({ children, alignType, className }) {
  var type = "all"
  if (alignType) {
    type = alignType
  }

  useEffect(() => {
    checkScrollTop()
    window.addEventListener("scroll", checkScrollTop, true)
    return () => {
      window.removeEventListener("scroll", checkScrollTop)
    }
  }, [])

  return (
    <>
      <div className={`pLShsVjRJm ${type} ${className}`}>{children}</div>
    </>
  )
}
