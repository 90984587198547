import ContainerMain from "src/pages/common/ContainerMain"
import image1 from "images/Product/Campaign/product-3-1a.png"
import { useFormatter } from "helpers/i18n"

import "./ModelConcept.scss"

export default function ModelConcept({ extraFooter = null }) {
  const { __ } = useFormatter()
  return (
    <>
      <div className="TgCFPpSuJm-model">
        <ContainerMain>
          <div className="VWySdLLKwa">
            <div className="SXoRqGRTZg">
              <div className="FSKzfNpjVs">
                <div className="WHBaNESkqr">
                  <img className="BWtJTGJKzA" src={image1} alt="" />
                </div>
              </div>
              <div className="FCjitlxKsb">
                <div
                  className="JDOVFGvteG"
                  dangerouslySetInnerHTML={{
                    __html: __({
                      defaultMessage: "A. Next-Best-Offer - Model Concept",
                    }),
                  }}
                ></div>
              </div>
            </div>
            <div className="model-concept-content-wapper">
              <div className="model-concept-content">
                <div className="WTKPvPwjkv-2">
                  <div className="AdwbmnHxqQ">
                    <div className="mZogAJKRvX">
                      {__({ defaultMessage: "Objective" })}
                    </div>
                    <div className="VynXYuiuKj">
                      {__({
                        defaultMessage:
                          "Grow revenue by recommending the most relevant products for the customer's next purchase",
                      })}
                    </div>
                  </div>
                  <div className="AdwbmnHxqQ">
                    <div className="mZogAJKRvX">
                      {__({ defaultMessage: "Analytics" })}
                    </div>
                    <div className="VynXYuiuKj">
                      {__({
                        defaultMessage:
                          "AI analyzes entire customer database to find the highest potential SKUs for every customer’s next purchase",
                      })}
                    </div>
                  </div>
                  <div className="AdwbmnHxqQ">
                    <div className="mZogAJKRvX">
                      {__({ defaultMessage: "Potential ROI" })}
                    </div>
                    <div className="VynXYuiuKj">
                      {__({
                        defaultMessage: "50% increase in conversion rate",
                      })}
                    </div>
                  </div>
                </div>

                <div
                  className="sQEPOxcDwQ pc"
                  dangerouslySetInnerHTML={{
                    __html: extraFooter,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            className="sQEPOxcDwQ mobile"
            dangerouslySetInnerHTML={{
              __html: extraFooter,
            }}
          ></div>
        </ContainerMain>
      </div>
    </>
  )
}
